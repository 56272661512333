import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [

    {
      path: "/",
      name: "home",
      component: () => import("./views/Ask.vue"),
    },
    {
      path: "/over",
      name: "over",
      component: () => import("./views/Over.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Contact.vue"),
    },
    {
      path: "/mentions",
      name: "mentions",
      component: () => import("./views/Mentions.vue"),
    },
    {
      path: "/ask",
      name: "ask",
      component: () => import("./views/Ask.vue"),
    }
    ,
    {
      path: "/home2",
      name: "home2",
      component: () => import("./views/Home2.vue"),
    },
    {
      path: "/form",
      name: "formulaire",
      component: () => import("./views/Form.vue"),
    },
    {
      path: "/game",
      name: "game",
      component: () => import("./views/Game.vue"),
    },
    {
      path: "/produits",
      name: "produits",
      component: () => import("./views/Produits.vue"),
    },    
    {
      path: "/status/:_id/:_secu",
      name: "status",
      component: () => import("./views/Status.vue"),
    },  
  ],
});

export default router;
